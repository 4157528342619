"use strict";

var heyday = heyday || {};

/**
Lazyfade: heyday.lazy.fade()
This items are already loaded in the DOM,
we just reveal them, once they enter the viewport.

Example Markup:
<div class="lazyfade">
    ++text inside++
</div>
**/

heyday.animate = new function () {

    function activateLazyfade(item) {
        setTimeout(() => {
            //console.log('item', item);
            item.classList.add('lazyfade--active');

        }, 500);
    }

    this.fadeUp = function (type) {

        var lazyfade = document.querySelector(".lazyfade");
        
        if (lazyfade) {
            
            //Intersection observer
            const options = {
                rootMargin: '300px',
                threshold: 0
            };

            const io = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    //console.log('entry: ', entry);
                    //console.log('entry.intersectionRatio: ', entry.intersectionRatio);

                    if (entry.intersectionRatio > 0) {
                        activateLazyfade(entry.target);
                        io.unobserve(entry.target);
                    }
                });
            }, options);

            const targetElements = document.querySelectorAll(".lazyfade");
            for (let element of targetElements) {
                //console.log('element: ', element);
                io.observe(element);
            }

        }
    };

};


//Run on load
(function () {
    document.addEventListener("DOMContentLoaded", function () {
        heyday.animate.fadeUp();
    });
}());