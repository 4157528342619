
$(".read-more-btn").click(function ()
{
    $btn = $(this);
	var more = $(this).attr("data-more-text");
	var less = $(this).attr("data-less-text");

    //Open block
    $btn.parent().prev().toggleClass("opened");

    //Update read more text
    if ($btn.hasClass("active")) {
        $btn.removeClass("active");
        $btn.find(".txt").text(more);
    }
    else {
        $btn.addClass("active");
        $btn.find(".txt").text(less);
    }
});