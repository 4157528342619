
var groundMaster = new function () {

    this.init = function () {
      groundMaster.backToTop();
    };

    this.backToTop = function() {
 
        var offset = 250;
        var duration = 300;
         
        $(window).scroll(function() {
            if ($(this).scrollTop() > offset) {
                $('.back-to-top').addClass('active');
            } 
            else {
                $('.back-to-top').removeClass('active');
            }
        });
         
        $('.back-to-top').click(function(event) {
            $('html, body').animate({scrollTop: 0}, duration);    
            return false;
        });
        
    };

}


$(document).ready(function () {
    groundMaster.init();
});