

var hdTopMaster = new function () {

    const top = document.querySelector("#top");
    const headerWrapper = document.querySelector(".header-wrapper");
    const backdrop = document.querySelector(".backdrop");
    const burgerBtn = document.querySelector(".burger-btn");

    function openTopNav() {

        headerWrapper.classList.add("header-wrapper--active");
        backdrop.classList.add("backdrop--active");
        //console.log("---openTopNav + backdrop---");

    }

    function closeTopNav() {
        
        headerWrapper.classList.remove("header-wrapper--active");
        backdrop.classList.remove("backdrop--active");
        //console.log("---closeTopNav---");

    }

    function openMobileMenu() {
        
        top.classList.add("mobile-nav-open");
        burgerBtn.classList.add("active");
        //console.log("---openMobileMenu---");

    }

    function closeMobileMenu() {
        
        top.classList.remove("mobile-nav-open");
        burgerBtn.classList.remove("active");
        $(".has-children").removeClass("active");

        //console.log("---closeMobileMenu---");

    }

    function openCurrentMegadropdown(item, event) {
        
        var dropdownTrigger = item,
            dropdownId = $(dropdownTrigger).attr("data-dropdown-id"),
            dropdownIdHeight = $(dropdownId).attr("data-height"),
            dropdownIdSiblings = $(dropdownId).siblings();

        if (dropdownId != '' & dropdownId != undefined) {

            function openMegadropdown() {

                //We close all siblings first
                closeAllMegadropdowns();

                //Then open active megadropdown
                $(dropdownTrigger).addClass("active");
                TweenMax.to(dropdownId, .7, { height: dropdownIdHeight, ease: Power4.easeOut });

                //console.log("---openMegadropdown---");

            }

            if ($(this).hasClass("active")) {
                //CLOSE
                closeTopNav();
                closeAllMegadropdowns();
            }
            else {
                //OPEN
                closeMobileMenu();
                openTopNav();
                openMegadropdown();
            }
        }

        event.stopPropagation();

    }

    function closeAllMegadropdowns() {

        $(".is-mega-dropdown, .is-mega-dropdown .header, .toggle-megadropdown").removeClass("active");
        TweenMax.to($(".is-mega-dropdown"), .7, { height: 0, ease: Power4.easeOut });

        //console.log("---closeAllMegadropdowns---");

    }

    this.backdropClicked = function () {
        
        backdrop.onclick = function () {
            closeTopNav();
            closeMobileMenu();
            closeAllMegadropdowns();
        };

    };

    this.mobileNavigation = function () {

        //Toggle mobile menu
        burgerBtn.onclick = function () {

            if (burgerBtn.classList.contains("active")) {
                closeMobileMenu();
                closeTopNav();
            }
            else {
                openMobileMenu();
                openTopNav();
                closeAllMegadropdowns();
            }

        };

        //Toggle mobile submenu
        $("#mobile-nav .arrow").click(function (e) {

            $(this).parent().toggleClass("active");
            $(this).closest("li").siblings().find("a").removeClass("active");
            e.preventDefault();

        });

    };

    this.megadropdownNavigation = function () {
        /*
            Trigger example: 
            <button type="button" class="open-megadropdown" data-dropdown-id="#mega-dropdown-search">
                <i class="fa fa-search"></i>
            </button> 
        */

        //1. Add megadropdowns height to animate
        $(".is-mega-dropdown").each(
            function (intIndex) {

                var thisHeight = $(this).find(".container").outerHeight();
                $(this).attr("data-height", thisHeight);

            }
        );

        //2. Toggle the current megadropdown
        $('.toggle-megadropdown').hoverIntent(function (event) {
            openCurrentMegadropdown(event.target, event);
        }, function () { });

    };

    this.init = function () {
        hdTopMaster.mobileNavigation();
        hdTopMaster.megadropdownNavigation();
        hdTopMaster.backdropClicked();
    };

}


$(document).ready(function () {
    hdTopMaster.init();
});