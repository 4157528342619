(function () {

    var videoPlayer = function () {
        //On overlay click, we get the video id and embed a video URL into an iframe.

        $(".video__overlay").click(function () {

            var videoOverlay = $(this);
            var videoWrapper = $(this).closest(".video");
            var videoContent = $(this).closest(".video__content");
            var videoId = $(this).attr('data-video-id');
            var videoPlayer = $(videoWrapper).find('.video__player');

            if (videoContent.find(".vimeo").length) {
                //If vimeo
                var videoEmbedUrl = 'https://player.vimeo.com/video/' + videoId + '?autoplay=1" width="500" height="300" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen';

                if ($(videoOverlay).is(".show-in-overlay")) {
                    //If open overlay
                    $(".fullscreen-video-overlay__iframe-wrap").html('<iframe class="fullscreen-video-overlay__iframe" src="' + videoEmbedUrl + '"></iframe>');
                    $(".fullscreen-video-overlay").addClass("fullscreen-video-overlay--active");
                }
                else {
                    //If open inside player
                    $(videoOverlay).fadeOut();
                    $(videoPlayer).html('<iframe src="' + videoEmbedUrl + '"></iframe>');
                }
            }
            else {
                //Otherwise must be YouTube
                var videoEmbedUrl = '//www.youtube.com/embed/' + videoId + '?enablejsapi=1&rel=0&autoplay=1&showinfo=0&wmode=transparent';

                if ($(videoOverlay).is(".show-in-overlay")) {
                    //If open overlay
                    $(".fullscreen-video-overlay__iframe-wrap").html('<iframe class="fullscreen-video-overlay__iframe" src="' + videoEmbedUrl + '"></iframe>');
                    $(".fullscreen-video-overlay").addClass("fullscreen-video-overlay--active");
                }
                else {
                    //If open inside player
                    $(videoOverlay).fadeOut();
                    $(videoPlayer).html('<iframe src="' + videoEmbedUrl + '"></iframe>');
                }
            }

        });
    }

    var onVideoOverlayClose = function () {
        $(".fullscreen-video-overlay__close").click(function () {
            $(".fullscreen-video-overlay").removeClass("fullscreen-video-overlay--active");
            setTimeout(function () {
                $(".fullscreen-video-overlay__iframe").attr("src", "about:blank");
            }, 1000);
        });
    }

    $(document).ready(function () {
        videoPlayer();
        onVideoOverlayClose();
    });

})();