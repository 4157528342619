"use strict";

var heyday = heyday || {};

/**
Lazyloading of images, videos & background images,
relative to the current breakpoint.

Dependencies:
- intersection-observer.js

---
Example markup(s):

<figure class="lazyload lazyload--background"
    data-src=""
    data-src-md=""
    data-src-sm=""
    data-src-xs=""
</figure>

<div class="lazyload-wrap">
    <img class="lazyload lazyload--image"
        data-src="@imageUrl"
        alt="@altText"
        data-width="@imageWidth"
        data-height="@imageHeight" />
</div>
**/

function mediaCropUrl(src, width, height)
{
    if (window.URL)
    {
        var url = null;
        if (src.startsWith("/"))
        {
            url = new window.URL(window.location.origin + src);
        } else
        {
            url = new window.URL(src);
        }
        if (width)
        {
            url.searchParams.set("width", width);
        }

        if (height)
        {
            url.searchParams.set("height", height);
        }
        return url.href;
    }
}
    
function loadMediaSrc(mediaElm) {
    
    if (mediaElm) {
        
        var mediaElmSrcXs = mediaElm.getAttribute("data-src-xs");
        var mediaElmSrcSm = mediaElm.getAttribute("data-src-sm");
        var mediaElmSrcMd = mediaElm.getAttribute("data-src-md");
        var mediaElmSrcLg = mediaElm.getAttribute("data-src");
        

        var newMediaSrc = mediaElmSrcLg;
        if (matchMedia) {
            //Breakpoints
            const mqXs = window.matchMedia("(max-width: 767px)");
            const mqSm = window.matchMedia("(min-width: 768px) and (max-width: 991px)");
            const mqMd = window.matchMedia("(min-width: 992px) and (max-width: 1200px)");

            if (mqXs.matches && mediaElmSrcXs !== null) {
                newMediaSrc = mediaElmSrcXs;
            }
            else if (mqSm.matches && mediaElmSrcSm !== null) {
                newMediaSrc = mediaElmSrcSm;
            }
            else if (mqMd.matches && mediaElmSrcMd !== null) {
                newMediaSrc = mediaElmSrcMd;
            }
        }
        //console.log('newMediaSrc: ' + newMediaSrc);

        if (newMediaSrc.startsWith("~"))
        {
            newMediaSrc = newMediaSrc.substring(1);
        }

        //Background?
        if (newMediaSrc && mediaElm.classList.contains("lazyload--background")) {
            mediaElm.style.backgroundImage = "url(" + newMediaSrc + ")";
            mediaElm.classList.add("lazyload--loaded");
            //this could use a load check...
        }

        //Image?
        if (newMediaSrc && mediaElm.classList.contains("lazyload--image"))
        {
            var dataWidth = parseInt(mediaElm.getAttribute("data-width") || 0);
            var dataHeight = parseInt(mediaElm.getAttribute("data-height") || 0);
            var dataHeightRatio = parseFloat(mediaElm.getAttribute("data-height-ratio") || 0);

            var clientWidth = mediaElm.offsetParent.clientWidth;
            if (dataWidth && dataWidth < clientWidth)
            {
                // Don't upscale
                clientWidth = dataWidth;
            }

            var height = null;
            if (dataHeightRatio)
            {
                height = clientWidth * dataHeightRatio;
            }

            newMediaSrc = mediaCropUrl(newMediaSrc, clientWidth, height);

            mediaElm.setAttribute("src", newMediaSrc);
            mediaElm.addEventListener("load", function (event) {
                mediaElm.classList.add("lazyload--loaded");
            });
        }

        //Video?
        if (newMediaSrc && mediaElm.classList.contains("lazyload--video")) {
            var mediaElmDate = new Date();
            mediaElm.src = newMediaSrc + "?time=" + mediaElmDate.getTime();
            mediaElm.load();
            mediaElm.play();
            mediaElm.classList.add("lazyload--loaded");
            //this could use a load check...
        }

        //Image Wrap (Wrapper inherit´s the image aspect ratio, to avoid document height jumps)
        if (mediaElm.parentNode.classList.contains("lazyload-wrap")) {
            var mediaElmWidth = mediaElm.getAttribute("data-width");
            var mediaElmHeight = mediaElm.getAttribute("data-height");
            var mediaElmAspect = parseInt((elemHeight / elemWidth) * 100);

            //console.log('mediaElmWidth', mediaElmWidth);
            //console.log('mediaElmHeight', mediaElmHeight);
            //console.log('mediaElmAspect', mediaElmAspect);

            mediaElm.parentNode.style.paddingBottom = elemAspect + "%";
        }

    } //if (mediaElm) END
}


heyday.lazy = new function () {

    //Detect if media is in viewport, then load it´s media src
    this.load = function (type) {
        const ll = document.querySelector(".lazyload");

        if (ll) {
            const llItems = document.querySelectorAll(".lazyload");
            
            const options = {
                rootMargin: "500px",
                threshold: 0
            };

            const lazyloadIo = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    //console.log('entry: ', entry);
                    //console.log('entry.intersectionRatio: ', entry.intersectionRatio);

                    if (entry.intersectionRatio > 0) {
                        loadMediaSrc(entry.target);
                        lazyloadIo.unobserve(entry.target);
                    }
                });
            }, options);

            const targetElements = document.querySelectorAll(".lazyload");
            for (let element of targetElements) {
                lazyloadIo.observe(element);
            }

        }
    };

    this.init = function () {
        heyday.lazy.load();
    };

};

//On resize function
var llResizeTimeout;
function llResizeThrottler() {
    // Ignore resize events as long as
    // a rerunLazyload execution is in the queue
    if (!llResizeTimeout) {
        llResizeTimeout = setTimeout(function () {
            llResizeTimeout = null;
            heyday.lazy.init();
            // The rerunLazyload will execute at a rate of 15fps

        }, 66);
    }
}


//Run on load
heyday.lazy.init();

//Re-run on resize
(function () {
    window.addEventListener("resize", llResizeThrottler, false);
}());

